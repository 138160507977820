import React from 'react'
import Sidebar from '../containers/Sidebar'
import Datatable from '../containers/StockSistema/Datatable'
export default function Main() {
return (
  <div>
    <Sidebar/>
    <Datatable />
  </div>
  )
}
